const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}
$(".clients__list").slick({
  prevArrow: $(".clients__list-arrow--previous"),
  nextArrow: $(".clients__list-arrow--next"),
  responsive:[
    {
      breakpoint: breakpoints.md,
      settings:{
          slidesToShow: 1,
      }
    },
    {
      breakpoint: breakpoints.lg,
      settings:{
          slidesToShow: 2,
      }
    },
    {
      breakpoint: breakpoints.xl,
      settings:{
          slidesToShow: 3,
      }
    },
    {
      breakpoint: 10000,
      settings:"unslick"
    }
  ]
});
$(".stats__list").slick({
  prevArrow: $(".stats__list-arrow--previous"),
  nextArrow: $(".stats__list-arrow--next"),
  responsive:[
    {
      breakpoint: breakpoints.md,
      settings:{
          slidesToShow: 1,
      }
    },
    {
      breakpoint: breakpoints.lg,
      settings:{
          slidesToShow: 2,
      }
    },
    {
      breakpoint: breakpoints.xl,
      settings:{
          slidesToShow: 3,
      }
    },
    {
      breakpoint: 10000,
      settings:"unslick"
    }
  ]
});
$(".landing-testimonials__slider").slick({
  dots: true,
  arrows: true,
  appendDots: $(".landing-testimonials__slider-dots"),
  prevArrow: $(".landing-testimonials__slider-arrow--prev"),
  nextArrow: $(".landing-testimonials__slider-arrow--next"),
  autoplay: true,
  autoplaySpeed: 3000,
  responsive:[
    {
      breakpoint: 10000,
      settings:{
        slidesToShow: 1
      }
    }
  ]
});
$(".landing-clients__images").slick({
  infinite:true,
  arrows:false,
  slidesToShow: 7,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive:[
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 10000,
      settings: {
        slidesToShow: 7
      }
    }
  ]
})