// Toggle FAQ items when clicking on the header
$(".faqs__item-header").on("click", function(e){
  $(this).closest(".faqs__item").toggleClass("faqs__item--active");
});

// Toggle Tabs dropdown on mobile when clicking on the btn
$(".tabs__btn").on("click", function(e){
  $(this).closest(".tabs").toggleClass("tabs--open");
});

// Scroll to DM card when tab item is clicked

$(".tabs--enable-hover .tabs__list .tabs__item").on("click", function(e){
  const index = $(this).index();
  $("html,body").animate({
    scrollTop: $(".text-img__item").eq(index).offset().top - 100
  },500);
});
  
// Toggle subservices item when header is clicked
$(".subservices__item").on("mouseover", function(e){
  $(this).addClass("subservices__item--active");
});
$(".subservices__item").on("mouseleave", function(e){
  $(this).removeClass("subservices__item--active");
});

// Open contact sidebar when header CTA is clicked 

$("button[data-form-btn]").on("click", function(e){
  e.preventDefault();    
  $(".sidebar").addClass("sidebar--active");
  $("body").addClass("no-scroll--mobile");
});

// Close contact sidebar when close btn is clicked
$(document).on("click", ".sidebar__close-btn", function(e) {
  e.preventDefault();
  $(".sidebar").removeClass("sidebar--active");
  $("body").removeClass("no-scroll--mobile");
});

// Close contact sidebar when clicking outside the sidebar
$(document).on("click", function(e){
  if(!$(e.target).closest(".sidebar").length && !$(e.target).closest("button[data-form-btn]").length){
    $(".sidebar").removeClass("sidebar--active");
    $("body").removeClass("no-scroll--mobile");
  }
});

const heroVideo = document.querySelector(".hero__bg--video");

if(heroVideo){
  const video = heroVideo;
  // Hide all text elements when hero button is clicked
  $(".hero__cta").on("click", function(e){
    e.preventDefault();
    $(window).scrollTop(0);
    $("body").addClass("no-scroll");
    $(".hero").addClass("hero--animate");
    $(".nav").addClass("nav--animate");
    let volume = 0;
    let step = 0.05;
    let maxVolume = 1;
    video.volume = volume;
    video.muted = false;
    // unmute hero video
    const fadeAudio = () => {
      if (volume < maxVolume) {
        volume += step;
        video.volume = volume;
      } else {
          // Stop the interval when max volume is reached
          clearInterval(fadeAudio);
      }
    }
    setInterval(fadeAudio, 100);
  });
  
  // Handle player controls 
  $(".hero__video-control").on("click", function(e){
    e.preventDefault();
    const action = $(this).data('action');
    switch(action){
      case "close":
        $(".hero").removeClass("hero--animate");
        $(".nav").removeClass("nav--animate");
        $("body").removeClass("no-scroll");
        video.muted = true;
        video.play();
        $(".hero__video-control[data-action='play'").hide();
        $(".hero__video-control[data-action='pause'").show();
        break;
      case "play":
        video.play();
        $(this).hide();
        $(".hero__video-control[data-action='pause'").show();
        break;
      case "pause":
        video.pause();
        $(this).hide();
        $(".hero__video-control[data-action='play'").show();
        break;
      case "play-back":
        video.currentTime = 0;
        video.play();
        $(".hero__video-control[data-action='play'").hide();
        $(".hero__video-control[data-action='pause'").show();
        break;
      default:
        break;
    }
  });
  
  // seek video when user clicks on a point in progress bar 
  $(".hero__video-progressbar").on("click", function(e){
    const progressBarWidth = $(this).width();
    const clickX = e.offsetX; 
  
    // Calculate the percentage of the click position relative to the progress bar width
    const clickPercentage = (clickX / progressBarWidth) * 100;
  
    // Calculate the corresponding time in the video based on the click percentage
    const videoDuration = video.duration;
    const newTime = (clickPercentage / 100) * videoDuration;
  
    // Set the video's current time to the new calculated time
    video.currentTime = newTime;
  });
  
  // Video progress bar
  video.addEventListener('timeupdate', function(e){
    const currentTime = video.currentTime;
    const duration = video.duration;  
    const progress = (currentTime / duration) * 100;
    $(".hero__video-progressline").css('width', `${progress}%`)
    if(progress > 99 && $(".hero").hasClass("hero--animate")){
      video.loop = false;
      $(".hero__video-content").addClass("hero__video-content--active");
    }
  });
  $(".hero__video-cta").on("click", function(e){
    e.preventDefault();
    $(".hero__video-content").removeClass("hero__video-content--active");
    $(".hero").removeClass("hero--animate");
    $(".nav").removeClass("nav--animate");
    $("body").removeClass("no-scroll");
    video.muted = true;
    video.loop = true;
    setTimeout(() => {
      $("html,body").animate({
        scrollTop: $(".clients").first().offset().top - 100
      },500);
      video.play();
    },1000);
  });
}

// const aboutVideo = document.querySelector(".video__media");
// if(aboutVideo){
//   const video = aboutVideo;
//     // Handle player controls 
//     $(".video__media-control").on("click", function(e){
//       e.preventDefault();
//       const action = $(this).data('action');
//       console.log("clicked");
//       switch(action){
//         case "play":
//           video.play();
//           $(this).hide();
//           $(".video__media-control[data-action='pause'").show();
//           break;
//         case "pause":
//           video.pause();
//           $(this).hide();
//           $(".video__media-control[data-action='play'").show();
//           break;
//         case "play-back":
//           video.currentTime = 0;
//           video.play();
//           $(".video__media-control[data-action='play'").hide();
//           $(".video__media-control[data-action='pause'").show();
//           break;
//         default:
//           break;
//       }
//     });
//     // seek video when user clicks on a point in progress bar 
//     $(".video__media-progressbar").on("click", function(e){
//       const progressBarWidth = $(this).width();
//       const clickX = e.offsetX; 
    
//       // Calculate the percentage of the click position relative to the progress bar width
//       const clickPercentage = (clickX / progressBarWidth) * 100;
    
//       // Calculate the corresponding time in the video based on the click percentage
//       const videoDuration = video.duration;
//       const newTime = (clickPercentage / 100) * videoDuration;
    
//       // Set the video's current time to the new calculated time
//       video.currentTime = newTime;
//     });
    
//   // Video progress bar
//   video.addEventListener('timeupdate', function(e){
//     const currentTime = video.currentTime;
//     const duration = video.duration;  
//     const progress = (currentTime / duration) * 100;
//     $(".video__media-progressline").css('width', `${progress}%`);
//   });
//   video.addEventListener('ended', function(e){
//     $(".video__media-control[data-action='play'").show();
//     $(".video__media-control[data-action='pause'").hide();
//   });
// }


/* Animation bootcode */
setTimeout(function(){
 $('.animated:in-viewport').addClass('animate');
 },1000);
$(window).on('scroll', function() {
   $('.animated:in-viewport').addClass('animate');
});


/* Captcha code */
let recaptchaSuccess = function (response, event) {
  console.log('Successful registration', response);

  // if this function was called as part of the form version
  // the event will be passed on so you can handle it as you please
  if(event){
      // in the case of a <form> submit event, this will "continue" the submission
      event.target.submit();
  }
};
        
let recaptchaFailure = function (response, event) {
  // console.log(response);
  console.error('We could not verify the user with Google reCaptcha 3: '+response['error-codes'].join(','))
};


$(document).on("click", ".contact__form-label[for='agreement']", function(e){
  $(this).find("input#agreement").prop('checked', function(i, val){
    return !val;
  });
});

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

$(document).on("click", ".contact__form-btn", function(e){
  e.preventDefault();
  const form = $(this).closest(".contact__form");
  let isValid = true;
  $(".contact__form-err").remove();

  // Validate other required fields
  form.find("input[required], textarea[required]").each(function() {
    if (!$(this).val().trim()) {
        $("<p class='contact__form-err'>This field is required.</p>")
            .insertAfter($(this));
        isValid = false;
    }
  });

  // Check for valid email
  const email = form.find("input[type='email']");
  if (email.length && !validateEmail(email.val())) {
      $("<p class='contact__form-err'>Please enter a valid email address.</p>")
          .insertAfter(email);
      isValid = false;
  }
  
  if (!form.find("input#agreement").prop('checked')){
    $(".contact__form-btn").before(`
      <p class="contact__form-err">
        You must accept the agreement before submitting the form.
      </p>
    `)
  }
  // If all validations pass, submit the form
  if (isValid) {
    form.submit();
  }
});